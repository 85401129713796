import React, { useState, useCallback, useEffect } from "react";
import "../style/font-style.css";
import { Box, Grid } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import Footer from "../components/Footer_nomal";
import Coworking from "../components/Placearea";
import Navigation from "../components/navigation";
import Img from "gatsby-image";
import "../css/base.css";
import WrokEnv from "../components/workEnv";
import TsuriEvent from "../components/tsuricationButton";
import ConceptBgImg from "../../static/images/concept_bg_img.png";
import Concept from "../components/concept";
import Carousel from "../components/Carousel";
import WorcarionBase from "../components/WorcationBase";
import News from "../components/news";
import mizutama from "../../static/images/mizutama.png";
import Map from "../components/map";
import NEWMAP from "../components/map";
import Fish from "../components/fish";
import FVVideo from "../../static/fishing-short.mp4";
import BoxTypo from "../components/BoxTypo";
import Faq from "../components/faq";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import WrapTypo from "../components/WrapTypo";
import InstagramEmbeded from "../components/InstagramEmbeded";
import BgImg from "../../static/images/bg.jpg";

export default function Home() {
  const [height, setHeight] = useState(0);
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeight((node.getBoundingClientRect().width * 9) / 16);
    }
    console.log("高さ変更");
  }, []);
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 2000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: true,
      anchorPlacement: "top-top",
    });
  }
  const data = useStaticQuery(graphql`
    query {
      paper1: file(relativePath: { eq: "paper1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paper2: file(relativePath: { eq: "paper2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      title1: file(relativePath: { eq: "contest_result_title.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      title2: file(relativePath: { eq: "contest_result_title2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      r1th: file(relativePath: { eq: "1th.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      r2th: file(relativePath: { eq: "2th.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      r3th: file(relativePath: { eq: "3th.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      r4th: file(relativePath: { eq: "4th.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      r5th: file(relativePath: { eq: "5th.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rana_1: file(relativePath: { eq: "ana_1th.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rana_2: file(relativePath: { eq: "ana_2th.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Box
      width="100%"
      bgcolor="#fff"
      style={{
        backgroundImage: `url(${BgImg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        overflowY: "scroll",
        height: "100vh",
      }}
    >
      <Box width={["95%", "80%"]} maxWidth="600px" m={"auto"}></Box>
      <Head />
      <Box
        width={["95%", "95%", "70%"]}
        ml={["auto", "auto", "auto"]}
        m={["auto", "auto", "auto"]}
        textAlign={"center"}
      >
        <Box width={["95%", "80%"]} maxWidth="600px" m={"auto"}>
          <Img fluid={data.title1.childImageSharp.fluid} />
          <BoxTypo
            mt={2}
            fs={["12px"]}
            ls={["2px"]}
            lh={["170%"]}
            textAlign={"justify"}
            Notosans={true}
            color="#187FB8"
            fw={"bold"}
          >
            庄内浜での釣りとワーケーションの魅力を発信し、それを目的とした首都圏等からの羽田＝庄内線を利用者の増加につなげるため、「庄内浜釣りケーションフォトダービーコンテスト」を開催しました。
            このたび、入賞作品を決定しましたのでお知らせいたします。
          </BoxTypo>
          <BoxTypo
            fs={["14px"]}
            ls={["2px"]}
            lh={["170%"]}
            mt={4}
            textAlign={"justify"}
            Notosans={true}
            color="#454545"
          >
            【概要】
          </BoxTypo>
          <table
            style={{
              verticalAlign: "top",
              color: "#454545",
              margin: "16px 0 32px 0",
              textAlign: "justify",
            }}
          >
            <tbody>
              <tr>
                <td valign="top" width={"80px"}>
                  <BoxTypo
                    fs={["12px"]}
                    ls={["2px"]}
                    lh={["200%"]}
                    textAlign={"left"}
                    Notosans={true}
                  >
                    募集期間：
                  </BoxTypo>
                </td>
                <td valign="top">
                  <BoxTypo
                    fs={["12px"]}
                    ls={["2px"]}
                    lh={["200%"]}
                    textAlign={"left"}
                    Notosans={true}
                  >
                    令和4年9月30日（金曜日）～12月23日（金曜日）
                  </BoxTypo>
                </td>
              </tr>
              <tr>
                <td valign="top" width={"50px"}>
                  <BoxTypo
                    fs={["12px"]}
                    ls={["2px"]}
                    lh={["200%"]}
                    textAlign={"left"}
                    Notosans={true}
                  >
                    応募結果：
                  </BoxTypo>
                </td>
                <td valign="top">
                  <BoxTypo
                    fs={["12px"]}
                    ls={["2px"]}
                    lh={["200%"]}
                    textAlign={"left"}
                    Notosans={true}
                  >
                    応募総数「庄内浜釣りケーション2022」514点、「ANA庄内浜釣りケーション2022」30点
                  </BoxTypo>
                </td>
              </tr>
              <tr>
                <td valign="top" width={"50px"}>
                  <BoxTypo
                    fs={["12px"]}
                    ls={["2px"]}
                    lh={["200%"]}
                    textAlign={"left"}
                    Notosans={true}
                  >
                    入賞作品：
                  </BoxTypo>
                </td>
                <td valign="top">
                  <BoxTypo
                    fs={["12px"]}
                    ls={["2px"]}
                    lh={["200%"]}
                    textAlign={"left"}
                    Notosans={true}
                  >
                    1位～5位各1点、ANA特別賞2点
                  </BoxTypo>
                </td>
              </tr>
            </tbody>
          </table>
          <Box mt={5} mb={3}>
            <Img fluid={data.title2.childImageSharp.fluid} />
          </Box>
        </Box>
        <Box
          width={["95%", "95%", "80%"]}
          maxWidth={"600px"}
          ml={["auto", "auto", "auto"]}
          m={["auto", "auto", "auto"]}
          bgcolor="#fff"
        >
          <Box width={"100%"} m={"auto"} boxShadow={3}>
            <Img fluid={data.r1th.childImageSharp.fluid} />
            <BoxTypo
              fs={["24px"]}
              ls={["2px"]}
              lh={["200%"]}
              Tsukubrdgothic={true}
              width="100%"
              color="#fff"
              bgcolor="#5BC6DD"
            >
              第１位
            </BoxTypo>
            <BoxTypo
              fs={["20px"]}
              ls={["2px"]}
              lh={["200%"]}
              Tsukubrdgothic={true}
            >
              <a
                link="https://www.instagram.com/p/Cmgs6_PP9Jf/?utm_source=ig_web_copy_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                @akit_0lllllll0さん
              </a>
            </BoxTypo>
            <BoxTypo
              fs={["14px"]}
              ls={["2px"]}
              lh={["200%"]}
              Tsukubrdgothic={true}
            >
              撮影場所：加茂
            </BoxTypo>
          </Box>
        </Box>
        <Box width={["95%", "80%"]} m={"auto"} mt={4}>
          <Grid
            container
            alignContent="space-between"
            alignItems="space-between"
          >
            <Grid item xs={12} sm={6}>
              <Box
                bgcolor={"#fff"}
                width={["100%", "80%"]}
                m={"auto"}
                my={4}
                boxShadow={3}
                pb={2}
              >
                <Img fluid={data.r2th.childImageSharp.fluid} />
                <BoxTypo
                  fs={["24px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                  color="#fff"
                  width="100%"
                  bgcolor="#5BC6DD"
                >
                  第２位
                </BoxTypo>
                <BoxTypo
                  fs={["20px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                >
                  <a
                    href="https://www.instagram.com/p/CkSHJukJMvH/?utm_source=ig_web_copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    t.sato29さん
                  </a>
                </BoxTypo>
                <BoxTypo
                  fs={["14px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                >
                  撮影場所：酒田沖
                </BoxTypo>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                bgcolor={"#fff"}
                width={["100%", "80%"]}
                m={"auto"}
                my={4}
                boxShadow={3}
                pb={2}
              >
                <Img fluid={data.r3th.childImageSharp.fluid} />
                <BoxTypo
                  fs={["24px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                  color="#fff"
                  width="100%"
                  bgcolor="#5BC6DD"
                >
                  第３位
                </BoxTypo>
                <BoxTypo
                  fs={["20px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                >
                  <a
                    href="https://twitter.com/ikeiokiei/status/1606263136201461762?s=20"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @ikeiokieiさん
                  </a>
                </BoxTypo>
                <BoxTypo
                  fs={["14px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                >
                  撮影場所：酒田沖
                </BoxTypo>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                bgcolor={"#fff"}
                width={["100%", "80%"]}
                m={"auto"}
                my={4}
                boxShadow={3}
                pb={2}
              >
                <Img fluid={data.r4th.childImageSharp.fluid} />
                <BoxTypo
                  fs={["24px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                  color="#fff"
                  width="100%"
                  bgcolor="#5BC6DD"
                >
                  第４位
                </BoxTypo>
                <BoxTypo
                  fs={["20px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                >
                  <a
                    href="https://www.instagram.com/p/CkvA_zIJ8Oi/?utm_source=ig_web_copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @tonjoroimojiさん
                  </a>
                </BoxTypo>
                <BoxTypo
                  fs={["14px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                >
                  撮影場所：庄内浜
                </BoxTypo>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                bgcolor={"#fff"}
                width={["100%", "80%"]}
                m={"auto"}
                my={4}
                boxShadow={3}
                pb={2}
              >
                <Img fluid={data.r5th.childImageSharp.fluid} />
                <BoxTypo
                  fs={["24px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                  color="#fff"
                  width="100%"
                  bgcolor="#5BC6DD"
                >
                  第５位
                </BoxTypo>
                <BoxTypo
                  fs={["20px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                >
                  <a
                    href="https://www.instagram.com/p/CmeSnkJPxkL/?utm_source=ig_web_copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @ryutayosiuraさん
                  </a>
                </BoxTypo>
                <BoxTypo
                  fs={["14px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                >
                  撮影場所：庄内沖
                </BoxTypo>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                bgcolor={"#fff"}
                width={["100%", "80%"]}
                m={"auto"}
                my={4}
                boxShadow={3}
                pb={2}
              >
                <Img fluid={data.rana_1.childImageSharp.fluid} />
                <BoxTypo
                  fs={["24px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                  color="#fff"
                  width="100%"
                  bgcolor="#5BC6DD"
                >
                  ANA特別賞
                </BoxTypo>
                <BoxTypo
                  fs={["20px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                >
                  <a
                    href="https://www.instagram.com/p/CmgN8zFv-aU/?utm_source=ig_web_copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @hana_aukaiさん
                  </a>
                </BoxTypo>
                <BoxTypo
                  fs={["14px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                >
                  撮影場所：庄内沖
                </BoxTypo>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                bgcolor={"#fff"}
                width={["100%", "80%"]}
                m={"auto"}
                my={4}
                boxShadow={3}
                pb={2}
              >
                <Img fluid={data.rana_2.childImageSharp.fluid} />
                <BoxTypo
                  fs={["24px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                  color="#fff"
                  width="100%"
                  bgcolor="#5BC6DD"
                >
                  ANA特別賞
                </BoxTypo>
                <BoxTypo
                  fs={["20px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                >
                  <a
                    href="https://www.instagram.com/p/CmfoG86vQgY/?utm_source=ig_web_copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @ksksurfさん
                  </a>
                </BoxTypo>
                <BoxTypo
                  fs={["14px"]}
                  ls={["2px"]}
                  lh={["200%"]}
                  Tsukubrdgothic={true}
                >
                  撮影場所：由良
                </BoxTypo>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Link className="bottom" to="/" width="150px">
        <BoxTypo
          Tsukubrdgothic
          fs={["18px"]}
          ls={["2px"]}
          lh={["200%"]}
          mt={[5]}
          mb={[0]}
          textAlign="center"
        >
          ←TOPへ戻る
        </BoxTypo>
      </Link>

      <Footer />
    </Box>
  );
}
